import BasicCountryLink from '@/Handlers/BasicCountryLink';
import { Row, Col, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
// Translation Higher Order Component
import { translate } from 'react-switch-lang';

const Africa = ({ t }) => {
	return (
		<UncontrolledPopover placement="top" offset="50, -166" trigger="legacy" target="AF">
			<PopoverHeader>
				<div className="country_list non-selectable">{t('countries.Africa')}</div>
			</PopoverHeader>
			<PopoverBody>
				<Row>
					<Col>
						<BasicCountryLink
							title={t('countries.North_Africa.Algeria.title')}
							photo="/images/countries/algeria.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'typhoid',
								'rabies',
								'yellowfever',
								'tuberculosis',
								'malaria'
							]}
							country={'North_Africa.Algeria.title'}
							text1={'North_Africa.Algeria.text1'}
							text2={'North_Africa.Algeria.text2'}
							map_lat="26.980828590472107"
							map_long="2.6367187500000004"
						/>
						<BasicCountryLink
							title={t('countries.Southern_Africa.Angola.title')}
							photo="/images/countries/angola.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'malaria',
								'yellowfever',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Southern_Africa.Angola.title'}
							text1={'Southern_Africa.Angola.text1'}
							text2={'Southern_Africa.Angola.text2'}
							map_lat="-14.26438308756265"
							map_long="17.929687500000004"
						/>
						<BasicCountryLink
							title={t('countries.West_Africa.Benin.title')}
							photo="/images/countries/benin.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis',
								'meningococcaldiseases'
							]}
							country={'West_Africa.Benin.title'}
							text1={'West_Africa.Benin.text1'}
							text2={'West_Africa.Benin.text2'}
							map_lat="8.581021215641854"
							map_long="2.1093750000000004"
						/>
						<BasicCountryLink
							title={t('countries.Southern_Africa.Botswana.title')}
							photo="/images/countries/botswana.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Southern_Africa.Botswana.title'}
							text1={'Southern_Africa.Botswana.text1'}
							text2={'Southern_Africa.Botswana.text2'}
							map_lat="-23.079731762449878"
							map_long="24.433593750000004"
						/>
						<BasicCountryLink
							title={t('countries.West_Africa.Burkina_Faso.title')}
							photo="/images/countries/burkina_faso.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis',
								'meningococcaldiseases'
							]}
							country={'West_Africa.Burkina_Faso.title'}
							text1={'West_Africa.Burkina_Faso.text1'}
							text2={'West_Africa.Burkina_Faso.text2'}
							map_lat="11.86735091145932"
							map_long="-1.4941406250000002"
						/>
						<BasicCountryLink
							title={t('countries.East_Africa.Burundi.title')}
							photo="/images/countries/burundi.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'East_Africa.Burundi.title'}
							text1={'East_Africa.Burundi.text1'}
							text2={'East_Africa.Burundi.text2'}
							map_lat="-3.688855143147035"
							map_long="29.926757812500004"
						/>
						<BasicCountryLink
							title={t('countries.Central_Africa.Cameroon.title')}
							photo="/images/countries/cameroon.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis',
								'meningococcaldiseases'
							]}
							country={'Central_Africa.Cameroon.title'}
							text1={'Central_Africa.Cameroon.text1'}
							text2={'Central_Africa.Cameroon.text2'}
							map_lat="3.8094460989409775"
							map_long="11.524658203125002"
						/>
						<BasicCountryLink
							title={t('countries.West_Africa.Cape_Verde.title')}
							photo="/images/countries/cape_verde.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'West_Africa.Cape_Verde.title'}
							text1={'West_Africa.Cape_Verde.text1'}
							text2={'West_Africa.Cape_Verde.text2'}
							map_lat="15.665354182093287"
							map_long="-23.73046875"
						/>
						<BasicCountryLink
							title={t('countries.Central_Africa.Chad.title')}
							photo="/images/countries/chad.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Central_Africa.Chad.title'}
							text1={'Central_Africa.Chad.text1'}
							text2={'Central_Africa.Chad.text2'}
							map_lat="11.178401873711785"
							map_long="18.281250000000004"
						/>
						<BasicCountryLink
							title={t('countries.Central_Africa.Congo.title')}
							photo="/images/countries/congo.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Central_Africa.Congo.title'}
							text1={'Central_Africa.Congo.text1'}
							text2={'Central_Africa.Congo.text2'}
							map_lat="-0.8788717828324148"
							map_long="22.67578125"
						/>
						<BasicCountryLink
							title={t('countries.West_Africa.Cote_dIvoire.title')}
							photo="/images/countries/cote_divore.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'West_Africa.Cote_dIvoire.title'}
							text1={'West_Africa.Cote_dIvoire.text1'}
							text2={'West_Africa.Cote_dIvoire.text2'}
							map_lat="7.36246686553575"
							map_long="-4.218750000000001"
						/>
						<BasicCountryLink
							title={t('countries.Central_Africa.Central_African_Republic.title')}
							photo="/images/countries/central_african_republic.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis',
								'meningococcaldiseases'
							]}
							country={'Central_Africa.Central_African_Republic.title'}
							text1={'Central_Africa.Central_African_Republic.text1'}
							text2={'Central_Africa.Central_African_Republic.text2'}
							map_lat="6.140554782450308"
							map_long="19.951171875000004"
						/>
						<BasicCountryLink
							title={t('countries.North_Africa.Egypt.title')}
							photo="/images/countries/egypt.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'North_Africa.Egypt.title'}
							text1={'North_Africa.Egypt.text1'}
							text2={'North_Africa.Egypt.text2'}
							map_lat="24.84656534821976"
							map_long="29.531250000000004"
						/>
						<BasicCountryLink
							title={t('countries.Central_Africa.Equatorial_Guinea.title')}
							photo="/images/countries/equatorial_guinea.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Central_Africa.Equatorial_Guinea.title'}
							text1={'Central_Africa.Equatorial_Guinea.text1'}
							text2={'Central_Africa.Equatorial_Guinea.text2'}
							map_lat="1.3621763466641712"
							map_long="10.217285156250002"
						/>
						<BasicCountryLink
							title={t('countries.Central_Africa.Gabon.title')}
							photo="/images/countries/gabon.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Central_Africa.Gabon.title'}
							text1={'Central_Africa.Gabon.text1'}
							text2={'Central_Africa.Gabon.text2'}
							map_lat="-0.6591651462894504"
							map_long="11.381835937500002"
						/>
						<BasicCountryLink
							title={t('countries.West_Africa.Gambia.title')}
							photo="/images/countries/gambia.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis',
								'meningococcaldiseases'
							]}
							country={'West_Africa.Gambia.title'}
							text1={'West_Africa.Gambia.text1'}
							text2={'West_Africa.Gambia.text2'}
							map_lat="13.368243250897299"
							map_long="-15.952148437500002"
						/>
						<BasicCountryLink
							title={t('countries.West_Africa.Ghana.title')}
							photo="/images/countries/ghana.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis',
								'meningococcaldiseases'
							]}
							country={'West_Africa.Ghana.title'}
							text1={'West_Africa.Ghana.text1'}
							text2={'West_Africa.Ghana.text2'}
							map_lat="7.798078531355303"
							map_long="-1.0986328125000002"
						/>
						<BasicCountryLink
							title={t('countries.West_Africa.Guinea.title')}
							photo="/images/countries/guinea.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'West_Africa.Guinea.title'}
							text1={'West_Africa.Guinea.text1'}
							text2={'West_Africa.Guinea.text2'}
							map_lat="10.574222078332806"
							map_long="-10.700683593750002"
						/>
						<BasicCountryLink
							title={t('countries.West_Africa.Guinea_Bissau.title')}
							photo="/images/countries/guinea_bissau.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'West_Africa.Guinea_Bissau.title'}
							text1={'West_Africa.Guinea_Bissau.text1'}
							text2={'West_Africa.Guinea_Bissau.text2'}
							map_lat="11.781325296112277"
							map_long="-15.139160156250002"
						/>
						<BasicCountryLink
							title={t('countries.East_Africa.Kenya.title')}
							photo="/images/countries/kenya.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'East_Africa.Kenya.title'}
							text1={'East_Africa.Kenya.text1'}
							text2={'East_Africa.Kenya.text2'}
							map_lat="0.3515602939922709"
							map_long="37.92480468750001"
						/>
					</Col>
					<Col>
						<BasicCountryLink
							title={t('countries.Southern_Africa.Lesotho.title')}
							photo="/images/countries/lesotho.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Southern_Africa.Lesotho.title'}
							text1={'Southern_Africa.Lesotho.text1'}
							text2={'Southern_Africa.Lesotho.text2'}
							map_lat="-29.878755346037977"
							map_long="28.146972656250004"
						/>
						<BasicCountryLink
							title={t('countries.West_Africa.Liberia.title')}
							photo="/images/countries/liberia.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'West_Africa.Liberia.title'}
							text1={'West_Africa.Liberia.text1'}
							text2={'West_Africa.Liberia.text2'}
							map_lat="6.577303118123887"
							map_long="-9.42626953125"
						/>
						<BasicCountryLink
							title={t('countries.North_Africa.Libya.title')}
							photo="/images/countries/libya.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis',
								'meningococcaldiseases'
							]}
							country={'North_Africa.Libya.title'}
							text1={'North_Africa.Libya.text1'}
							text2={'North_Africa.Libya.text2'}
							map_lat="27.371767300523047"
							map_long="16.699218750000004"
						/>
						<BasicCountryLink
							title={t('countries.West_Africa.Mali.title')}
							photo="/images/countries/mali.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis',
								'meningococcaldiseases'
							]}
							country={'West_Africa.Mali.title'}
							text1={'West_Africa.Mali.text1'}
							text2={'West_Africa.Mali.text2'}
							map_lat="17.35063837604883"
							map_long="-1.8896484375000002"
						/>
						<BasicCountryLink
							title={t('countries.West_Africa.Mauritania.title')}
							photo="/images/countries/mauritania.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'West_Africa.Mauritania.title'}
							text1={'West_Africa.Mauritania.text1'}
							text2={'West_Africa.Mauritania.text2'}
							map_lat="19.062117883514652"
							map_long="-10.458984375000002"
						/>
						<BasicCountryLink
							title={t('countries.East_Africa.Madagascar.title')}
							photo="/images/countries/madagascar.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'East_Africa.Madagascar.title'}
							text1={'East_Africa.Madagascar.text1'}
							text2={'East_Africa.Madagascar.text2'}
							map_lat="-21.820707853875017"
							map_long="46.36230468750001"
						/>
						<BasicCountryLink
							title={t('countries.East_Africa.Malawi.title')}
							photo="/images/countries/malawi.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'East_Africa.Malawi.title'}
							text1={'East_Africa.Malawi.text1'}
							text2={'East_Africa.Malawi.text2'}
							map_lat="-13.453737213419249"
							map_long="33.48632812500001"
						/>
						<BasicCountryLink
							title={t('countries.East_Africa.Mauritius.title')}
							photo="/images/countries/mauritius.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'East_Africa.Mauritius.title'}
							text1={'East_Africa.Mauritius.text1'}
							text2={'East_Africa.Mauritius.text2'}
							map_lat="-20.370377256347652"
							map_long="57.54638671875"
						/>
						<BasicCountryLink
							title={t('countries.East_Africa.Mozambique.title')}
							photo="/images/countries/mozambique.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'East_Africa.Mozambique.title'}
							text1={'East_Africa.Mozambique.text1'}
							text2={'East_Africa.Mozambique.text2'}
							map_lat="-21.04349121680354"
							map_long="34.36523437500001"
						/>
						<BasicCountryLink
							title={t('countries.North_Africa.Morocco.title')}
							photo="/images/countries/morocco.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'North_Africa.Morocco.title'}
							text1={'North_Africa.Morocco.text1'}
							text2={'North_Africa.Morocco.text2'}
							map_lat="29.38217507514529"
							map_long="-8.789062500000002"
						/>
						<BasicCountryLink
							title={t('countries.Southern_Africa.Namibia.title')}
							photo="/images/countries/nambia.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Southern_Africa.Namibia.title'}
							text1={'Southern_Africa.Namibia.text1'}
							text2={'Southern_Africa.Namibia.text2'}
							map_lat="-25.085598897064763"
							map_long="17.248535156250004"
						/>
						<BasicCountryLink
							title={t('countries.West_Africa.Nigeria.title')}
							photo="/images/countries/nigeria.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis',
								'meningococcaldiseases'
							]}
							country={'West_Africa.Nigeria.title'}
							text1={'West_Africa.Nigeria.text1'}
							text2={'West_Africa.Nigeria.text2'}
							map_lat="8.754794702435618"
							map_long="7.382812500000001"
						/>
						<BasicCountryLink
							title={t('countries.East_Africa.Rwanda.title')}
							photo="/images/countries/rwanda.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'East_Africa.Rwanda.title'}
							text1={'East_Africa.Rwanda.text1'}
							text2={'East_Africa.Rwanda.text2'}
							map_lat="-2.3284603685731593"
							map_long="29.663085937500004"
						/>
						<BasicCountryLink
							title={t('countries.North_Africa.Sudan.title')}
							photo="/images/countries/sudan.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis',
								'meningococcaldiseases'
							]}
							country={'North_Africa.Sudan.title'}
							text1={'North_Africa.Sudan.text1'}
							text2={'North_Africa.Sudan.text2'}
							map_lat="29.38217507514529"
							map_long="-8.789062500000002"
						/>
						<BasicCountryLink
							title={t('countries.West_Africa.Senegal.title')}
							photo="/images/countries/senegal.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis',
								'meningococcaldiseases'
							]}
							country={'West_Africa.Senegal.title'}
							text1={'West_Africa.Senegal.text1'}
							text2={'West_Africa.Senegal.text2'}
							map_lat="14.00869637063467"
							map_long="-14.765625000000002"
						/>
						<BasicCountryLink
							title={t('countries.West_Africa.Sierra_Leone.title')}
							photo="/images/countries/sierra_leone.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'West_Africa.Sierra_Leone.title'}
							text1={'West_Africa.Sierra_Leone.text1'}
							text2={'West_Africa.Sierra_Leone.text2'}
							map_lat="8.276727101164047"
							map_long="-11.997070312500002"
						/>
						<BasicCountryLink
							title={t('countries.East_Africa.Tanzania.title')}
							photo="/images/countries/tanzania.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'East_Africa.Tanzania.title'}
							text1={'East_Africa.Tanzania.text1'}
							text2={'East_Africa.Tanzania.text2'}
							map_lat="-6.227933930268672"
							map_long="34.49707031250001"
						/>
						<BasicCountryLink
							title={t('countries.North_Africa.Tunisia.title')}
							photo="/images/countries/tunisia.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'North_Africa.Tunisia.title'}
							text1={'North_Africa.Tunisia.text1'}
							text2={'North_Africa.Tunisia.text2'}
							map_lat="33.61461929233378"
							map_long="9.360351562500002"
						/>
						<BasicCountryLink
							title={t('countries.West_Africa.Togo.title')}
							photo="/images/countries/togo.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis',
								'meningococcaldiseases'
							]}
							country={'West_Africa.Togo.title'}
							text1={'West_Africa.Togo.text1'}
							text2={'West_Africa.Togo.text2'}
							map_lat="8.53756535080403"
							map_long="1.0546875000000002"
						/>
						<BasicCountryLink
							title={t('countries.East_Africa.Uganda.title')}
							photo="/images/countries/uganda.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis',
								'meningococcaldiseases'
							]}
							country={'East_Africa.Uganda.title'}
							text1={'East_Africa.Uganda.text1'}
							text2={'East_Africa.Uganda.text2'}
							map_lat="1.0546279422758869"
							map_long="32.60742187500001"
						/>
						<BasicCountryLink
							title={t('countries.East_Africa.Zambia.title')}
							photo="/images/countries/zambia.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'East_Africa.Zambia.title'}
							text1={'East_Africa.Zambia.text1'}
							text2={'East_Africa.Zambia.text2'}
							map_lat="-14.519780046326085"
							map_long="27.070312500000004"
						/>
					</Col>
				</Row>
			</PopoverBody>
		</UncontrolledPopover>
	);
};
export default translate(Africa);
