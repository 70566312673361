import BasicCountryLink from '@/Handlers/BasicCountryLink';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
// Translation Higher Order Component
import { translate } from 'react-switch-lang';

const Africa = ({ t }) => {
	return (
		<UncontrolledPopover placement="top" trigger="legacy" target="OC">
			<PopoverHeader>
				<div className="country_list non-selectable">{t('countries.Oceania.name')}</div>
			</PopoverHeader>
			<PopoverBody>
				<BasicCountryLink
					title={t('countries.Oceania.Australia.title')}
					photo="/images/countries/australia.webp"
					vaccines={[ 'tetanus', 'diphtheria', 'yellow_fever', 'japanese_brain_inflammation' ]}
					country={'Oceania.Australia.title'}
					text1={'Oceania.Australia.text1'}
					text2={'Oceania.Australia.text2'}
					map_lat="-28.76765910569124"
					map_long="134.29687500000003"
				/>
				<BasicCountryLink
					title={t('countries.Oceania.Cook_Islands.title')}
					photo="/images/countries/cook_islands.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'typhoid',
						'tuberculosis',
						'malaria'
					]}
					country={'Oceania.Cook_Islands.title'}
					text1={'Oceania.Cook_Islands.text1'}
					text2={'Oceania.Cook_Islands.text2'}
					map_lat="-17.978733095556155"
					map_long="203.86230468750003"
				/>
				<BasicCountryLink
					title={t('countries.Oceania.Hawaii.title')}
					photo="/images/countries/hawaii.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria'
					]}
					country={'Oceania.Hawaii.title'}
					text1={'Oceania.Hawaii.text1'}
					text2={'Oceania.Hawaii.text2'}
					map_lat="19.621892180319374"
					map_long="-155.53344726562503"
				/>
				<BasicCountryLink
					title={t('countries.Oceania.Fiji.title')}
					photo="/images/countries/fiji.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria'
					]}
					country={'Oceania.Fiji.title'}
					text1={'Oceania.Fiji.text1'}
					text2={'Oceania.Fiji.text2'}
					map_lat="-17.769612247142668"
					map_long="178.13232421875"
				/>
				<BasicCountryLink
					title={t('countries.Oceania.New_Zealand.title')}
					photo="/images/countries/new_zealand.webp"
					vaccines={[ 'tetanus', 'diphtheria' ]}
					country={'Oceania.New_Zealand.title'}
					text1={'Oceania.New_Zealand.text1'}
					text2={'Oceania.New_Zealand.text2'}
					map_lat="-43.13306116240613"
					map_long="171.5625"
				/>
				<BasicCountryLink
					title={t('countries.Oceania.Papua_New_Guinea.title')}
					photo="/images/countries/papua_new_guinea.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria',
						'japanese_brain_inflammation'
					]}
					country={'Oceania.Papua_New_Guinea.title'}
					text1={'Oceania.Papua_New_Guinea.text1'}
					text2={'Oceania.Papua_New_Guinea.text2'}
					map_lat="-6.664607562172573"
					map_long="144.66796875000003"
				/>
				<BasicCountryLink
					title={t('countries.Oceania.Samoa.title')}
					photo="/images/countries/samoa.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria'
					]}
					country={'Oceania.Samoa.title'}
					text1={'Oceania.Samoa.text1'}
					text2={'Oceania.Samoa.text2'}
					map_lat="-13.944729974920167"
					map_long="188.228759765625"
				/>
				<BasicCountryLink
					title={t('countries.Oceania.Solomon_Islands.title')}
					photo="/images/countries/solomon_islands.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria'
					]}
					country={'Oceania.Solomon_Islands.title'}
					text1={'Oceania.Solomon_Islands.text1'}
					text2={'Oceania.Solomon_Islands.text2'}
					map_lat="-8.667918002363107"
					map_long="158.81835937500003"
				/>
				<BasicCountryLink
					title={t('countries.Oceania.Tahiti.title')}
					photo="/images/countries/tahiti.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria'
					]}
					country={'Oceania.Tahiti.title'}
					text1={'Oceania.Tahiti.text1'}
					text2={'Oceania.Tahiti.text2'}
					map_lat="-17.65449123359241"
					map_long="210.53924560546878"
				/>
				<BasicCountryLink
					title={t('countries.Oceania.Tonga.title')}
					photo="/images/countries/tonga.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'typhoid',
						'tuberculosis',
						'malaria'
					]}
					country={'Oceania.Tonga.title'}
					text1={'Oceania.Tonga.text1'}
					text2={'Oceania.Tonga.text2'}
					map_lat="-21.231782183740137"
					map_long="-175.1481628417969"
				/>
				<BasicCountryLink
					title={t('countries.Oceania.Vanuatu.title')}
					photo="/images/countries/vanuatu.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'typhoid',
						'tuberculosis',
						'malaria'
					]}
					country={'Oceania.Vanuatu.title'}
					text1={'Oceania.Vanuatu.text1'}
					text2={'Oceania.Vanuatu.text2'}
					map_lat="-16.762467717941604"
					map_long="168.04687500000003"
				/>
			</PopoverBody>
		</UncontrolledPopover>
	);
};
export default translate(Africa);
