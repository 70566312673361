import BasicCountryLink from '@/Handlers/BasicCountryLink';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
// Translation Higher Order Component
import { translate } from 'react-switch-lang';

const Africa = ({ t }) => {
	return (
		<UncontrolledPopover placement="bottom" offset="0, -60" trigger="legacy" target="SA">
			<PopoverHeader>
				<div className="country_list non-selectable">{t('countries.South_America.name')}</div>
			</PopoverHeader>
			<PopoverBody>
				<BasicCountryLink
					title={t('countries.South_America.Argentina.title')}
					photo="/images/countries/argentina.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria'
					]}
					country={'South_America.Argentina.title'}
					text1={'South_America.Argentina.text1'}
					text2={'South_America.Argentina.text2'}
					map_lat="-39.77476948529546"
					map_long="-66.79687500000001"
				/>
				<BasicCountryLink
					title={t('countries.South_America.Bolivia.title')}
					photo="/images/countries/bolivia.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria',
						'rabies'
					]}
					country={'South_America.Bolivia.title'}
					text1={'South_America.Bolivia.text1'}
					text2={'South_America.Bolivia.text2'}
					map_lat="-16.97274101999902"
					map_long="-64.51171875000001"
				/>
				<BasicCountryLink
					title={t('countries.South_America.Brazil.title')}
					photo="/images/countries/brazil.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria',
						'rabies'
					]}
					country={'South_America.Brazil.title'}
					text1={'South_America.Brazil.text1'}
					text2={'South_America.Brazil.text2'}
					map_lat="-13.2399454992863"
					map_long="-50.09765625000001"
				/>
				<BasicCountryLink
					title={t('countries.South_America.Chile.title')}
					photo="/images/countries/chile.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'typhoid',
						'tuberculosis',
						'malaria'
					]}
					country={'South_America.Chile.title'}
					text1={'South_America.Chile.text1'}
					text2={'South_America.Chile.text2'}
					map_lat="-41.17865397233169"
					map_long="-72.86132812500001"
				/>
				<BasicCountryLink
					title={t('countries.South_America.Colombia.title')}
					photo="/images/countries/colombia.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria',
						'rabies'
					]}
					country={'South_America.Colombia.title'}
					text1={'South_America.Colombia.text1'}
					text2={'South_America.Colombia.text2'}
					map_lat="2.6357885741666065"
					map_long="-73.47656250000001"
				/>
				<BasicCountryLink
					title={t('countries.Carribean.Curacao.title')}
					photo="/images/countries/curacao.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria'
					]}
					country={'Carribean.Curacao.title'}
					text1={'Carribean.Curacao.text1'}
					text2={'Carribean.Curacao.text2'}
					map_lat="12.146745814539685"
					map_long="-68.96118164062501"
				/>
				<BasicCountryLink
					title={t('countries.South_America.Equador.title')}
					photo="/images/countries/equador.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria',
						'rabies'
					]}
					country={'South_America.Equador.title'}
					text1={'South_America.Equador.text1'}
					text2={'South_America.Equador.text2'}
					map_lat="-2.284550660236957"
					map_long="-79.1015625"
				/>
				<BasicCountryLink
					title={t('countries.South_America.Paraguay.title')}
					photo="/images/countries/paraguay.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria',
						'rabies'
					]}
					country={'South_America.Paraguay.title'}
					text1={'South_America.Paraguay.text1'}
					text2={'South_America.Paraguay.text2'}
					map_lat="-23.725011735951796"
					map_long="-58.88671875000001"
				/>
				<BasicCountryLink
					title={t('countries.South_America.Peru.title')}
					photo="/images/countries/peru.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria',
						'rabies'
					]}
					country={'South_America.Peru.title'}
					text1={'South_America.Peru.text1'}
					text2={'South_America.Peru.text2'}
					map_lat="-9.79567758282973"
					map_long="-76.46484375000001"
				/>
				<BasicCountryLink
					title={t('countries.Carribean.Trinidad_Tobago.title')}
					photo="/images/countries/tobago.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria'
					]}
					country={'Carribean.Trinidad_Tobago.title'}
					text1={'Carribean.Trinidad_Tobago.text1'}
					text2={'Carribean.Trinidad_Tobago.text2'}
					map_lat="10.487811882056695"
					map_long="-61.25976562500001"
				/>
				<BasicCountryLink
					title={t('countries.South_America.Uruguay.title')}
					photo="/images/countries/uruguay.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'typhoid',
						'tuberculosis',
						'malaria'
					]}
					country={'South_America.Uruguay.title'}
					text1={'South_America.Uruguay.text1'}
					text2={'South_America.Uruguay.text2'}
					map_lat="-33.35806161277886"
					map_long="-56.07421875000001"
				/>
				<BasicCountryLink
					title={t('countries.South_America.Venezuela.title')}
					photo="/images/countries/venezuela.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria',
						'rabies'
					]}
					country={'South_America.Venezuela.title'}
					text1={'South_America.Venezuela.text1'}
					text2={'South_America.Venezuela.text2'}
					map_lat="5.61598581915534"
					map_long="-65.83007812500001"
				/>
			</PopoverBody>
		</UncontrolledPopover>
	);
};
export default translate(Africa);
