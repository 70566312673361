import BasicCountryLink from '@/Handlers/BasicCountryLink';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
// Translation Higher Order Component
import { translate } from 'react-switch-lang';

const Africa = ({ t }) => {
	return (
		<UncontrolledPopover placement="top" offset="0, -54" trigger="legacy" target="EU">
			<PopoverHeader>
				<div className="country_list non-selectable">{t('countries.Europe.name')}</div>
			</PopoverHeader>
			<PopoverBody>
				<BasicCountryLink
					title={t('countries.Europe.Austria.title')}
					photo="/images/countries/austria.webp"
					vaccines={[ 'tetanus', 'diphtheria', 'tbe' ]}
					country={'Europe.Austria.title'}
					text1={'Europe.Austria.text1'}
					text2={'Europe.Austria.text2'}
					map_lat="47.368594345213374"
					map_long="14.458007812500002"
				/>
				<BasicCountryLink
					title={t('countries.Europe.Croatia.title')}
					photo="/images/countries/croatia.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'tbe',
						'hepatitisa',
						'hepatitisb',
						'typhoid',
						'tuberculosis',
						'malaria'
					]}
					country={'Europe.Croatia.title'}
					text1={'Europe.Croatia.text1'}
					text2={'Europe.Croatia.text2'}
					map_lat="45.213003555993964"
					map_long="15.292968750000002"
				/>
				<BasicCountryLink
					title={t('countries.Europe.Cyprus.title')}
					photo="/images/countries/cyprus.webp"
					vaccines={[ 'tetanus', 'diphtheria' ]}
					country={'Europe.Cyprus.title'}
					text1={'Europe.Cyprus.text1'}
					text2={'Europe.Cyprus.text2'}
					map_lat="34.88593094075317"
					map_long="33.22265625000001"
				/>
				<BasicCountryLink
					title={t('countries.Europe.Czech_Republic.title')}
					photo="/images/countries/czech_republic.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'tbe',
						'hepatitisa',
						'hepatitisb',
						'typhoid',
						'tuberculosis',
						'malaria'
					]}
					country={'Europe.Czech_Republic.title'}
					text1={'Europe.Czech_Republic.text1'}
					text2={'Europe.Czech_Republic.text2'}
					map_lat="49.61070993807422"
					map_long="15.468750000000002"
				/>
				<BasicCountryLink
					title={t('countries.Europe.Denmark.title')}
					photo="/images/countries/denmark.webp"
					vaccines={[ 'tetanus', 'diphtheria' ]}
					country={'Europe.Denmark.title'}
					text1={'Europe.Denmark.text1'}
					text2={'Europe.Denmark.text2'}
					map_lat="55.85064987433714"
					map_long="9.624023437500002"
				/>
				<BasicCountryLink
					title={t('countries.Europe.England.title')}
					photo="/images/countries/england.webp"
					vaccines={[ 'tetanus', 'diphtheria' ]}
					country={'Europe.England.title'}
					text1={'Europe.England.text1'}
					text2={'Europe.England.text2'}
					map_lat="54.57206165565852"
					map_long="-2.9443359375"
				/>
				<BasicCountryLink
					title={t('countries.Europe.France.title')}
					photo="/images/countries/france.webp"
					vaccines={[ 'tetanus', 'diphtheria' ]}
					country={'Europe.France.title'}
					text1={'Europe.France.text1'}
					text2={'Europe.France.text2'}
					map_lat="46.92025531537454"
					map_long="2.4609375000000004"
				/>
				<BasicCountryLink
					title={t('countries.Europe.Germany.title')}
					photo="/images/countries/germany.webp"
					vaccines={[ 'tetanus', 'diphtheria' ]}
					country={'Europe.Germany.title'}
					text1={'Europe.Germany.text1'}
					text2={'Europe.Germany.text2'}
					map_lat="50.958426723359935"
					map_long="10.458984375000002"
				/>
				<BasicCountryLink
					title={t('countries.Europe.Greece.title')}
					photo="/images/countries/greece.webp"
					vaccines={[ 'tetanus', 'diphtheria' ]}
					country={'Europe.Greece.title'}
					text1={'Europe.Greece.text1'}
					text2={'Europe.Greece.text2'}
					map_lat="39.095962936305476"
					map_long="22.060546875"
				/>
				<BasicCountryLink
					title={t('countries.Europe.Ireland.title')}
					photo="/images/countries/ireland.webp"
					vaccines={[ 'tetanus', 'diphtheria' ]}
					country={'Europe.Ireland.title'}
					text1={'Europe.Ireland.text1'}
					text2={'Europe.Ireland.text2'}
					map_lat="53.27835301753182"
					map_long="-8.173828125000002"
				/>
				<BasicCountryLink
					title={t('countries.Europe.Iceland.title')}
					photo="/images/countries/iceland.webp"
					vaccines={[ 'tetanus', 'diphtheria' ]}
					country={'Europe.Iceland.title'}
					text1={'Europe.Iceland.text1'}
					text2={'Europe.Iceland.text2'}
					map_lat="64.8115572502203"
					map_long="-18.720703125000004"
				/>
				<BasicCountryLink
					title={t('countries.Europe.Italy.title')}
					photo="/images/countries/italy.webp"
					vaccines={[ 'tetanus', 'diphtheria' ]}
					country={'Europe.Italy.title'}
					text1={'Europe.Italy.text1'}
					text2={'Europe.Italy.text2'}
					map_lat="41.96765920367816"
					map_long="12.919921875000002"
				/>
				<BasicCountryLink
					title={t('countries.Europe.Norway.title')}
					photo="/images/countries/norway.webp"
					vaccines={[ 'tetanus', 'diphtheria' ]}
					country={'Europe.Norway.title'}
					text1={'Europe.Norway.text1'}
					text2={'Europe.Norway.text2'}
					map_lat="61.64816245852389"
					map_long="9.228515625000002"
				/>
				<BasicCountryLink
					title={t('countries.Europe.Portugal.title')}
					photo="/images/countries/portugal.webp"
					vaccines={[ 'tetanus', 'diphtheria' ]}
					country={'Europe.Portugal.title'}
					text1={'Europe.Portugal.text1'}
					text2={'Europe.Portugal.text2'}
					map_lat="39.436192999314095"
					map_long="-8.437500000000002"
				/>
				<BasicCountryLink
					title={t('countries.Europe.Russia.title')}
					photo="/images/countries/russia.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'tbe',
						'hepatitisa',
						'hepatitisb',
						'typhoid',
						'tuberculosis',
						'malaria',
						'rabies',
						'japanese_brain_inflammation'
					]}
					country={'Europe.Russia.title'}
					text1={'Europe.Russia.text1'}
					text2={'Europe.Russia.text2'}
					map_lat="58.53959476664049"
					map_long="44.6484375"
				/>
				<BasicCountryLink
					title={t('countries.Europe.Spain.title')}
					photo="/images/countries/spain.webp"
					vaccines={[ 'tetanus', 'diphtheria' ]}
					country={'Europe.Spain.title'}
					text1={'Europe.Spain.text1'}
					text2={'Europe.Spain.text2'}
					map_lat="39.232253141714914"
					map_long="-3.6914062500000004"
				/>
				<BasicCountryLink
					title={t('countries.Europe.Sweden.title')}
					photo="/images/countries/sweden.webp"
					vaccines={[ 'tetanus', 'diphtheria', 'tbe' ]}
					country={'Europe.Sweden.title'}
					text1={'Europe.Sweden.text1'}
					text2={'Europe.Sweden.text2'}
					map_lat="60.84491057364915"
					map_long="15.292968750000002"
				/>
				<BasicCountryLink
					title={t('countries.Europe.Turkey.title')}
					photo="/images/countries/turkey.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'tbe',
						'hepatitisa',
						'hepatitisb',
						'typhoid',
						'tuberculosis',
						'malaria'
					]}
					country={'Europe.Turkey.title'}
					text1={'Europe.Turkey.text1'}
					text2={'Europe.Turkey.text2'}
					map_lat="38.685509760012025"
					map_long="35.15625000000001"
				/>
			</PopoverBody>
		</UncontrolledPopover>
	);
};
export default translate(Africa);
