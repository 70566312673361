import BasicCountryLink from '@/Handlers/BasicCountryLink';
import { Row, Col, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
// Translation Higher Order Component
import { translate } from 'react-switch-lang';

const Africa = ({ t }) => {
	return (
		<UncontrolledPopover placement="top" offset="-19, -172" trigger="legacy" target="AS">
			<PopoverHeader>
				<div className="country_list non-selectable">{t('countries.Asia')}</div>
			</PopoverHeader>
			<PopoverBody>
				<Row>
					<Col>
						<BasicCountryLink
							title={t('countries.Central_Asia.Afghanistan.title')}
							photo="/images/countries/afghanistan.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Central_Asia.Afghanistan.title'}
							text1={'Central_Asia.Afghanistan.text1'}
							text2={'Central_Asia.Afghanistan.text2'}
							map_lat="33.43144133557529"
							map_long="66.44531250000001"
						/>
						<BasicCountryLink
							title={t('countries.South_Asia.Bangladesh.title')}
							photo="/images/countries/bangladesh.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'South_Asia.Bangladesh.title'}
							text1={'South_Asia.Bangladesh.text1'}
							text2={'South_Asia.Bangladesh.text2'}
							map_lat="23.443088931121785"
							map_long="90.21972656250001"
						/>
						<BasicCountryLink
							title={t('countries.Middle_East.Bahrain.title')}
							photo="/images/countries/bahrain.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Middle_East.Bahrain.title'}
							text1={'Middle_East.Bahrain.text1'}
							text2={'Middle_East.Bahrain.text2'}
							map_lat="26.081454458607542"
							map_long="50.548095703125"
						/>
						<BasicCountryLink
							title={t('countries.South_Asia.Bhutan.title')}
							photo="/images/countries/bhutan.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'South_Asia.Bhutan.title'}
							text1={'South_Asia.Bhutan.text1'}
							text2={'South_Asia.Bhutan.text2'}
							map_lat="27.254629577800088"
							map_long="90.26367187500001"
						/>
						<BasicCountryLink
							title={t('countries.Southeast_Asia.Brunei.title')}
							photo="/images/countries/brunei.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Southeast_Asia.Brunei.title'}
							text1={'Southeast_Asia.Brunei.text1'}
							text2={'Southeast_Asia.Brunei.text2'}
							map_lat="4.477856485570586"
							map_long="114.60937500000001"
						/>
						<BasicCountryLink
							title={t('countries.Southeast_Asia.Cambodia.title')}
							photo="/images/countries/cambodia.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Southeast_Asia.Cambodia.title'}
							text1={'Southeast_Asia.Cambodia.text1'}
							text2={'Southeast_Asia.Cambodia.text2'}
							map_lat="12.683214911818666"
							map_long="104.94140625000001"
						/>
						<BasicCountryLink
							title={t('countries.East_Asia.China.title')}
							photo="/images/countries/china.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'East_Asia.China.title'}
							text1={'East_Asia.China.text1'}
							text2={'East_Asia.China.text2'}
							map_lat="27.293689224852407"
							map_long="111.4453125"
						/>
						<BasicCountryLink
							title={t('countries.Southeast_Asia.East_Timor.title')}
							photo="/images/countries/east_timor.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Southeast_Asia.East_Timor.title'}
							text1={'Southeast_Asia.East_Timor.text1'}
							text2={'Southeast_Asia.East_Timor.text2'}
							map_lat="-8.841651120809145"
							map_long="125.94726562500001"
						/>
						<BasicCountryLink
							title={t('countries.East_Asia.Hong_Kong.title')}
							photo="/images/countries/hong_kong.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'East_Asia.Hong_Kong.title'}
							text1={'East_Asia.Hong_Kong.text1'}
							text2={'East_Asia.Hong_Kong.text2'}
							map_lat="22.268764039073968"
							map_long="114.16992187500001"
						/>
						<BasicCountryLink
							title={t('countries.Southeast_Asia.Indonesia.title')}
							photo="/images/countries/indonesia.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Southeast_Asia.Indonesia.title'}
							text1={'Southeast_Asia.Indonesia.text1'}
							text2={'Southeast_Asia.Indonesia.text2'}
							map_lat="-2.986927393334863"
							map_long="119.97070312500001"
						/>
						<BasicCountryLink
							title={t('countries.South_Asia.India.title')}
							photo="/images/countries/india.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'South_Asia.India.title'}
							text1={'South_Asia.India.text1'}
							text2={'South_Asia.India.text2'}
							map_lat="20.96143961409684"
							map_long="79.01367187500001"
						/>
						<BasicCountryLink
							title={t('countries.Middle_East.Iran.title')}
							photo="/images/countries/iran.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Middle_East.Iran.title'}
							text1={'Middle_East.Iran.text1'}
							text2={'Middle_East.Iran.text2'}
							map_lat="31.653381399664"
							map_long="53.78906250000001"
						/>
						<BasicCountryLink
							title={t('countries.Middle_East.Iraq.title')}
							photo="/images/countries/iraq.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Middle_East.Iraq.title'}
							text1={'Middle_East.Iraq.text1'}
							text2={'Middle_East.Iraq.text2'}
							map_lat="32.39851580247402"
							map_long="43.24218750000001"
						/>
						<BasicCountryLink
							title={t('countries.East_Asia.Japan.title')}
							photo="/images/countries/japan.webp"
							vaccines={[ 'tetanus', 'diphtheria', 'hepatitisb', 'japanesebraininflammation' ]}
							country={'East_Asia.Japan.title'}
							text1={'East_Asia.Japan.text1'}
							text2={'East_Asia.Japan.text2'}
							map_lat="36.4566360115962"
							map_long="139.21875000000003"
						/>
						<BasicCountryLink
							title={t('countries.Middle_East.Jordan.title')}
							photo="/images/countries/jordan.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Middle_East.Jordan.title'}
							text1={'Middle_East.Jordan.text1'}
							text2={'Middle_East.Jordan.text2'}
							map_lat="31.27855085894653"
							map_long="36.38671875000001"
						/>
						<BasicCountryLink
							title={t('countries.Middle_East.Kuwait.title')}
							photo="/images/countries/kuwait.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Middle_East.Kuwait.title'}
							text1={'Middle_East.Kuwait.text1'}
							text2={'Middle_East.Kuwait.text2'}
							map_lat="29.38217507514529"
							map_long="47.724609375"
						/>
						<BasicCountryLink
							title={t('countries.Southeast_Asia.Laos.title')}
							photo="/images/countries/laos.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis',
								'japanesebraininflammation'
							]}
							country={'Southeast_Asia.Laos.title'}
							text1={'Southeast_Asia.Laos.text1'}
							text2={'Southeast_Asia.Laos.text2'}
							map_lat="19.394067895396613"
							map_long="102.74414062500001"
						/>
						<BasicCountryLink
							title={t('countries.Middle_East.Lebanon.title')}
							photo="/images/countries/lebanon.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Middle_East.Lebanon.title'}
							text1={'Middle_East.Lebanon.text1'}
							text2={'Middle_East.Lebanon.text2'}
							map_lat="33.906895551288684"
							map_long="35.81542968750001"
						/>
						<BasicCountryLink
							title={t('countries.East_Asia.Macau.title')}
							photo="/images/countries/macau.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'East_Asia.Macau.title'}
							text1={'East_Asia.Macau.text1'}
							text2={'East_Asia.Macau.text2'}
							map_lat="22.158155060984672"
							map_long="113.56430053710939"
						/>
						<BasicCountryLink
							title={t('countries.Southeast_Asia.Malaysia.title')}
							photo="/images/countries/malaysia.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis',
								'japanesebraininflammation'
							]}
							country={'Southeast_Asia.Malaysia.title'}
							text1={'Southeast_Asia.Malaysia.text1'}
							text2={'Southeast_Asia.Malaysia.text2'}
							map_lat="3.5134210456400448"
							map_long="101.95312500000001"
						/>
						<BasicCountryLink
							title={t('countries.South_Asia.Maldives.title')}
							photo="/images/countries/maldives.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'South_Asia.Maldives.title'}
							text1={'South_Asia.Maldives.text1'}
							text2={'South_Asia.Maldives.text2'}
							map_lat="3.2063329870791444"
							map_long="73.08105468750001"
						/>
						<BasicCountryLink
							title={t('countries.East_Asia.Mongolia.title')}
							photo="/images/countries/mongolia.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'East_Asia.Mongolia.title'}
							text1={'East_Asia.Mongolia.text1'}
							text2={'East_Asia.Mongolia.text2'}
							map_lat="46.07323062540835"
							map_long="103.00781250000001"
						/>
					</Col>
					<Col>
						<BasicCountryLink
							title={t('countries.Southeast_Asia.Myanmar.title')}
							photo="/images/countries/myanmar.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis',
								'japanesebraininflammation'
							]}
							country={'Southeast_Asia.Myanmar.title'}
							text1={'Southeast_Asia.Myanmar.text1'}
							text2={'Southeast_Asia.Myanmar.text2'}
							map_lat="21.04349121680354"
							map_long="93.95507812500001"
						/>
						<BasicCountryLink
							title={t('countries.South_Asia.Nepal.title')}
							photo="/images/countries/nepal.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis',
								'japanesebraininflammation'
							]}
							country={'South_Asia.Nepal.title'}
							text1={'South_Asia.Nepal.text1'}
							text2={'South_Asia.Nepal.text2'}
							map_lat="27.605670826465445"
							map_long="85.25390625000001"
						/>
						<BasicCountryLink
							title={t('countries.East_Asia.North_Korea.title')}
							photo="/images/countries/north_korea.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis',
								'japanesebraininflammation'
							]}
							country={'East_Asia.North_Korea.title'}
							text1={'East_Asia.North_Korea.text1'}
							text2={'East_Asia.North_Korea.text2'}
							map_lat="39.842286020743394"
							map_long="126.91406250000001"
						/>
						<BasicCountryLink
							title={t('countries.Middle_East.Oman.title')}
							photo="/images/countries/oman.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Middle_East.Oman.title'}
							text1={'Middle_East.Oman.text1'}
							text2={'Middle_East.Oman.text2'}
							map_lat="21.04349121680354"
							map_long="56.86523437500001"
						/>
						<BasicCountryLink
							title={t('countries.South_Asia.Pakistan.title')}
							photo="/images/countries/pakistan.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis',
								'japanesebraininflammation'
							]}
							country={'South_Asia.Pakistan.title'}
							text1={'South_Asia.Pakistan.text1'}
							text2={'South_Asia.Pakistan.text2'}
							map_lat="28.14950321154457"
							map_long="65.65429687500001"
						/>
						<BasicCountryLink
							title={t('countries.Southeast_Asia.Philippines.title')}
							photo="/images/countries/philippines.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis',
								'japanesebraininflammation'
							]}
							country={'Southeast_Asia.Philippines.title'}
							text1={'Southeast_Asia.Philippines.text1'}
							text2={'Southeast_Asia.Philippines.text2'}
							map_lat="11.86735091145932"
							map_long="122.08007812500001"
						/>
						<BasicCountryLink
							title={t('countries.Middle_East.Qatar.title')}
							photo="/images/countries/qatar.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Middle_East.Qatar.title'}
							text1={'Middle_East.Qatar.text1'}
							text2={'Middle_East.Qatar.text2'}
							map_lat="25.284437746983055"
							map_long="51.19628906250001"
						/>
						<BasicCountryLink
							title={t('countries.Middle_East.Saudi_Arabia.title')}
							photo="/images/countries/saudi_arabia.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis',
								'meningococcaldiseases'
							]}
							country={'Middle_East.Saudi_Arabia.title'}
							text1={'Middle_East.Saudi_Arabia.text1'}
							text2={'Middle_East.Saudi_Arabia.text2'}
							map_lat="24.32707654001865"
							map_long="44.86816406250001"
						/>
						<BasicCountryLink
							title={t('countries.Southeast_Asia.Singapore.title')}
							photo="/images/countries/singapore.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Southeast_Asia.Singapore.title'}
							text1={'Southeast_Asia.Singapore.text1'}
							text2={'Southeast_Asia.Singapore.text2'}
							map_lat="1.3292264529974078"
							map_long="103.831787109375"
						/>
						<BasicCountryLink
							title={t('countries.South_Asia.Sri_Lanka.title')}
							photo="/images/countries/sri_lanka.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'malaria',
								'typhoid',
								'yellowfever',
								'rabies',
								'tuberculosis',
								'japanesebraininflammation'
							]}
							country={'South_Asia.Sri_Lanka.title'}
							text1={'South_Asia.Sri_Lanka.text1'}
							text2={'South_Asia.Sri_Lanka.text2'}
							map_lat="7.18810087117902"
							map_long="80.77148437500001"
						/>
						<BasicCountryLink
							title={t('countries.East_Asia.South_Korea.title')}
							photo="/images/countries/south_korea.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis',
								'japanesebraininflammation'
							]}
							country={'East_Asia.South_Korea.title'}
							text1={'East_Asia.South_Korea.text1'}
							text2={'East_Asia.South_Korea.text2'}
							map_lat="36.1733569352216"
							map_long="127.96875000000001"
						/>
						<BasicCountryLink
							title={t('countries.Middle_East.Syria.title')}
							photo="/images/countries/syria.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Middle_East.Syria.title'}
							text1={'Middle_East.Syria.text1'}
							text2={'Middle_East.Syria.text2'}
							map_lat="35.0659731379842"
							map_long="38.51806640625001"
						/>
						<BasicCountryLink
							title={t('countries.East_Asia.Taiwan.title')}
							photo="/images/countries/taiwan.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis',
								'japanesebraininflammation'
							]}
							country={'East_Asia.Taiwan.title'}
							text1={'East_Asia.Taiwan.text1'}
							text2={'East_Asia.Taiwan.text2'}
							map_lat="23.765236889758672"
							map_long="121.00341796875001"
						/>
						<BasicCountryLink
							title={t('countries.Central_Asia.Tajikistan.title')}
							photo="/images/countries/tajikistan.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Central_Asia.Tajikistan.title'}
							text1={'Central_Asia.Tajikistan.text1'}
							text2={'Central_Asia.Tajikistan.text2'}
							map_lat="38.75408327579141"
							map_long="70.57617187500001"
						/>
						<BasicCountryLink
							title={t('countries.Southeast_Asia.Thailand.title')}
							photo="/images/countries/thailand.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'malaria',
								'typhoid',
								'yellowfever',
								'rabies',
								'tuberculosis',
								'japanesebraininflammation'
							]}
							country={'Southeast_Asia.Thailand.title'}
							text1={'Southeast_Asia.Thailand.text1'}
							text2={'Southeast_Asia.Thailand.text2'}
							map_lat="13.068776734357694"
							map_long="100.72265625000001"
						/>
						<BasicCountryLink
							title={t('countries.Central_Asia.Turkmenistan.title')}
							photo="/images/countries/turkmenistan.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Central_Asia.Turkmenistan.title'}
							text1={'Central_Asia.Turkmenistan.text1'}
							text2={'Central_Asia.Turkmenistan.text2'}
							map_lat="38.89103282648846"
							map_long="58.71093750000001"
						/>
						<BasicCountryLink
							title={t('countries.Middle_East.UAE.title')}
							photo="/images/countries/uae.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'yellowfever',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Middle_East.UAE.title'}
							text1={'Middle_East.UAE.text1'}
							text2={'Middle_East.UAE.text2'}
							map_lat="23.56398712845123"
							map_long="53.78906250000001"
						/>
						<BasicCountryLink
							title={t('countries.Central_Asia.Uzbekistan.title')}
							photo="/images/countries/uzbekistan.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Central_Asia.Uzbekistan.title'}
							text1={'Central_Asia.Uzbekistan.text1'}
							text2={'Central_Asia.Uzbekistan.text2'}
							map_lat="41.902277040963696"
							map_long="63.10546875000001"
						/>
						<BasicCountryLink
							title={t('countries.Southeast_Asia.Vietnam.title')}
							photo="/images/countries/vietnam.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis',
								'japanesebraininflammation'
							]}
							country={'Southeast_Asia.Vietnam.title'}
							text1={'Southeast_Asia.Vietnam.text1'}
							text2={'Southeast_Asia.Vietnam.text2'}
							map_lat="14.26438308756265"
							map_long="108.45703125000001"
						/>
						<BasicCountryLink
							title={t('countries.Middle_East.Yemen.title')}
							photo="/images/countries/yemen.webp"
							vaccines={[
								'tetanus',
								'diphtheria',
								'hepatitisa',
								'hepatitisb',
								'malaria',
								'typhoid',
								'rabies',
								'tuberculosis'
							]}
							country={'Middle_East.Yemen.title'}
							text1={'Middle_East.Yemen.text1'}
							text2={'Middle_East.Yemen.text2'}
							map_lat="15.623036831528264"
							map_long="47.63671875000001"
						/>
					</Col>
				</Row>
			</PopoverBody>
		</UncontrolledPopover>
	);
};
export default translate(Africa);
