import React from 'react';
const WorldMap = require('react-world-map');
import BasePage from '@/components/BasePage';
import Africa from '@/pages/destinations/africa';
import Asia from '@/pages/destinations/asia';
import Europe from '@/pages/destinations/europe';
import NorthAmerica from '@/pages/destinations/northAmerica';
import SouthAmerica from '@/pages/destinations/southAmerica';
import Oceania from '@/pages/destinations/oceania';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Container } from 'reactstrap';

// Translation Higher Order Component
import { translate } from 'react-switch-lang';

const TravelsMap = ({ t }) => {
	return (
		//React.Fragment is used instead of  or <> to hide it from source
		<React.Fragment>
			<div className="map_container">
				<WorldMap />
				<div className="map-text_africa non-selectable">{t('countries.Africa')}</div>
				<div className="map-text_asia non-selectable">{t('countries.Asia')}</div>
				<div className="map-text_northamerica non-selectable">{t('countries.North_America.name')}</div>
				<div className="map-text_southamerica non-selectable">{t('countries.South_America.name')} </div>
				<div className="map-text_europe non-selectable">{t('countries.Europe.name')} </div>
				<div className="map-text_oceania non-selectable">{t('countries.Oceania.name')}</div>
			</div>

			<Africa />
			<Asia />
			<NorthAmerica />
			<SouthAmerica />
			<Europe />
			<Oceania />
		</React.Fragment>
	);
};
const Travels = ({ t }) => {
	return (
		//React.Fragment is used instead of  or <> to hide it from source
		<React.Fragment>
			{/* Base Page - Shared component with Container */}
			<BasePage id="travels-page" className="travels-page pt-5">
				<Container>
					<section>
						<Col className="no-padding">
							<h2 className="font-weight-bold text-center pt-5">
								<FontAwesomeIcon icon={[ 'fas', 'plane-departure' ]} /> &nbsp;
								{t('countries.destinations')}
							</h2>
							<p className="page_subtext text-center text-uppercase">{t('countries.pick_from_map')}</p>
						</Col>
						<TravelsMap t={t} />
					</section>
				</Container>
			</BasePage>
		</React.Fragment>
	);
};
export default translate(Travels);
