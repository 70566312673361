import BasicCountryLink from '@/Handlers/BasicCountryLink';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
// Translation Higher Order Component
import { translate } from 'react-switch-lang';

const Africa = ({ t }) => {
	return (
		<UncontrolledPopover placement="top" offset="0, -130" trigger="legacy" target="NA">
			<PopoverHeader>
				<div className="country_list non-selectable">{t('countries.North_America.name')}</div>
			</PopoverHeader>
			<PopoverBody>
				<BasicCountryLink
					title={t('countries.Carribean.Bahamas.title')}
					photo="/images/countries/bahamas.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'typhoid',
						'tuberculosis',
						'malaria'
					]}
					country={'Carribean.Bahamas.title'}
					text1={'Carribean.Bahamas.text1'}
					text2={'Carribean.Bahamas.text2'}
					map_lat="24.206889622398023"
					map_long="-77.23388671875"
				/>
				<BasicCountryLink
					title={t('countries.Carribean.Barbados.title')}
					photo="/images/countries/barbados.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria'
					]}
					country={'Carribean.Barbados.title'}
					text1={'Carribean.Barbados.text1'}
					text2={'Carribean.Barbados.text2'}
					map_lat="13.14367777049247"
					map_long="-59.55688476562501"
				/>

				<BasicCountryLink
					title={t('countries.Central_America.Belize.title')}
					photo="/images/countries/belize.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria',
						'rabies'
					]}
					country={'Central_America.Belize.title'}
					text1={'Central_America.Belize.text1'}
					text2={'Central_America.Belize.text2'}
					map_lat="17.035777250427195"
					map_long="-88.33007812500001"
				/>
				<BasicCountryLink
					title={t('countries.North_America.Canada.title')}
					photo="/images/countries/canada.webp"
					vaccines={[ 'tetanus', 'diphtheria' ]}
					country={'North_America.Canada.title'}
					text1={'North_America.Canada.text1'}
					text2={'North_America.Canada.text2'}
					map_lat="58.44773280389084"
					map_long="-111.79687500000001"
				/>
				<BasicCountryLink
					title={t('countries.Central_America.Costa_Rica.title')}
					photo="/images/countries/costa_rica.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria',
						'rabies'
					]}
					country={'Central_America.Costa_Rica.title'}
					text1={'Central_America.Costa_Rica.text1'}
					text2={'Central_America.Costa_Rica.text2'}
					map_lat="9.91474384324173"
					map_long="-84.07836914062501"
				/>
				<BasicCountryLink
					title={t('countries.Carribean.Cuba.title')}
					photo="/images/countries/cuba.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria',
						'rabies'
					]}
					country={'Carribean.Cuba.title'}
					text1={'Carribean.Cuba.text1'}
					text2={'Carribean.Cuba.text2'}
					map_lat="21.861498734372567"
					map_long="-79.54101562500001"
				/>
				<BasicCountryLink
					title={t('countries.Carribean.Dominican_Republic.title')}
					photo="/images/countries/dominican_republic.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria',
						'rabies'
					]}
					country={'Carribean.Dominican_Republic.title'}
					text1={'Carribean.Dominican_Republic.text1'}
					text2={'Carribean.Dominican_Republic.text2'}
					map_lat="18.812717856407776"
					map_long="-70.31250000000001"
				/>
				<BasicCountryLink
					title={t('countries.Central_America.El_Salvador.title')}
					photo="/images/countries/el_salvador.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria',
						'rabies'
					]}
					country={'Central_America.El_Salvador.title'}
					text1={'Central_America.El_Salvador.text1'}
					text2={'Central_America.El_Salvador.text2'}
					map_lat="13.624633438236152"
					map_long="-88.79150390625"
				/>
				<BasicCountryLink
					title={t('countries.Carribean.Grenada.title')}
					photo="/images/countries/grenada.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria',
						'rabies'
					]}
					country={'Carribean.Grenada.title'}
					text1={'Carribean.Grenada.text1'}
					text2={'Carribean.Grenada.text2'}
					map_lat="12.093038580274138"
					map_long="-61.69921875000001"
				/>
				<BasicCountryLink
					title={t('countries.Central_America.Guatemala.title')}
					photo="/images/countries/guatemala.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria',
						'rabies'
					]}
					country={'Central_America.Guatemala.title'}
					text1={'Central_America.Guatemala.text1'}
					text2={'Central_America.Guatemala.text2'}
					map_lat="15.178180945596363"
					map_long="-90.39550781250001"
				/>
				<BasicCountryLink
					title={t('countries.Central_America.Honduras.title')}
					photo="/images/countries/honduras.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria',
						'rabies'
					]}
					country={'Central_America.Honduras.title'}
					text1={'Central_America.Honduras.text1'}
					text2={'Central_America.Honduras.text2'}
					map_lat="14.71113475887066"
					map_long="-86.68212890625001"
				/>
				<BasicCountryLink
					title={t('countries.Carribean.Jamaica.title')}
					photo="/images/countries/jamaica.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria'
					]}
					country={'Carribean.Jamaica.title'}
					text1={'Carribean.Jamaica.text1'}
					text2={'Carribean.Jamaica.text2'}
					map_lat="18.104087015773956"
					map_long="-77.36572265625001"
				/>
				<BasicCountryLink
					title={t('countries.North_America.Mexico.title')}
					photo="/images/countries/mexico.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria',
						'rabies'
					]}
					country={'North_America.Mexico.title'}
					text1={'North_America.Mexico.text1'}
					text2={'North_America.Mexico.text2'}
					map_lat="21.94304553343818"
					map_long="-101.689453125"
				/>
				<BasicCountryLink
					title={t('countries.Carribean.Netherlands_Antilles.title')}
					photo="/images/countries/netherlands_antilles.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'typhoid',
						'tuberculosis',
						'malaria'
					]}
					country={'Carribean.Netherlands_Antilles.title'}
					text1={'Carribean.Netherlands_Antilles.text1'}
					text2={'Carribean.Netherlands_Antilles.text2'}
					map_lat="15.199386048560008"
					map_long="-61.43554687500001"
				/>
				<BasicCountryLink
					title={t('countries.Central_America.Nicaragua.title')}
					photo="/images/countries/nicaragua.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria',
						'rabies'
					]}
					country={'Central_America.Nicaragua.title'}
					text1={'Central_America.Nicaragua.text1'}
					text2={'Central_America.Nicaragua.text2'}
					map_lat="12.640338306846802"
					map_long="-85.51757812500001"
				/>
				<BasicCountryLink
					title={t('countries.Central_America.Panama.title')}
					photo="/images/countries/panama.webp"
					vaccines={[
						'tetanus',
						'diphtheria',
						'hepatitisa',
						'hepatitisb',
						'yellow_fever',
						'typhoid',
						'tuberculosis',
						'malaria',
						'rabies'
					]}
					country={'Central_America.Panama.title'}
					text1={'Central_America.Panama.text1'}
					text2={'Central_America.Panama.text2'}
					map_lat="9.037002898469423"
					map_long="-79.54101562500001"
				/>
				<BasicCountryLink
					title={t('countries.North_America.USA.title')}
					photo="/images/countries/usa.webp"
					vaccines={[ 'tetanus', 'diphtheria' ]}
					country={'North_America.USA.title'}
					text1={'North_America.USA.text1'}
					text2={'North_America.USA.text2'}
					map_lat="39.095962936305476"
					map_long="-97.55859375000001"
				/>
			</PopoverBody>
		</UncontrolledPopover>
	);
};
export default translate(Africa);
